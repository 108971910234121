import React from 'react'
import LogoReuse from '../../assets/img/logo-reuse-alargado.png'

const Footer = () => {
  return (
    <footer>
      <div className='container-xxl'>
        <div className='row'>
          <div className='col-12 d-flex justify-content-center py-4'>
            <a href='https://reuse.mx'><img src={LogoReuse} alt='Reuse.mx' /></a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import React from 'react'
import { ReactComponent as Moto } from '../../assets/svg/moto.svg'
import { ReactComponent as Check } from '../../assets/svg/check.svg'
interface OwnProps {
    paso: number
}

const Etapas : React.FC<OwnProps> = ({ paso }) => {
  return (
    <div className='etapas'>
      <div className={(paso === 3) ? 'etapas-wrapper entregado d-flex' : 'etapas-wrapper d-flex'}>
        <div className={(paso === 0) ? 'etapa active-moto' : (paso > 0) ? 'etapa active' : 'etapa'}>
          <div className='moto'><Moto /></div>
          <div className='circulo' />
          <div className='circulo-active' />
          <span className='text-dark'>Orden en preparación</span>
        </div>
        <div className={(paso === 1) ? 'etapa active-moto' : (paso > 1) ? 'etapa active' : 'etapa'}>
          <div className='moto'><Moto /></div>
          <div className='circulo' />
          <div className='circulo-active' />
          <span className='text-dark'>Retirado por operador</span>
        </div>
        <div className={(paso === 2) ? 'etapa active-moto' : (paso > 2) ? 'etapa active' : 'etapa'}>
          <div className='moto'><Moto /></div>
          <div className='circulo' />
          <div className='circulo-active' />
          <span className='text-dark'>En reparto</span>
        </div>
        <div className={(paso === 3) ? 'etapa active-moto' : (paso > 3) ? 'etapa active' : 'etapa'}>
          <div className='moto'><Check /></div>
          <div className='circulo' />
          <div className='circulo-active' />
          <span className='text-dark'>Producto entregado</span>
        </div>
      </div>
    </div>
  )
}

export default Etapas

import axios from 'axios'

const axiosInstance = axios.create({
  headers: { 'Content-type': 'application/json; charset=utf-8', Accept: 'text/json' }
})

export const WebApi = {
  getShipping: async function (q : any) {
    return await axiosInstance.request({
      method: 'GET',
      url: `${process.env.REACT_APP_CRUD_SHIPPING}getByOrderId/${q}`
    }).then((res: any) => res.data)
  },
  getShippingHistory: async function (q : any) {
    return await axiosInstance.request({
      method: 'GET',
      url: `${process.env.REACT_APP_CRUD_SHIPPING_HISTORY}getAllByOrderId/${q}`
    }).then((res: any) => res.data)
  }
}

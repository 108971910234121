import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from './components/Layout/Layout'
import HomePage from './pages/HomePage'
import StatusPage from './pages/StatusPage'
import './theme/App.scss'

function App () {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path='/status/:id_order_platform' element={<StatusPage />} />
      </Route>
    </Routes>
  )
}

export default App
